<template>
	<section class="declined-loan">
		<Portal to="hero">
			<StsTitle class="is-desktop" isSmall>
				Welcome back! The status of your loan application has been updated
			</StsTitle>
		</Portal>

		<StsTitle class="is-mobile" isSmall>
			Welcome back! The status of your loan application has been updated
		</StsTitle>

		<DeclinedMessage />

		<aside class="content-container tile-container">
			<a class="tile" title="Open Online Loan Applications" :href="companyWebsiteLink" target="_blank">
				<StsOutlinedIcon name="Desert Financial Logo" :width="iconDimension" :height="iconDimension">
					<DFMark />
				</StsOutlinedIcon>
				<span>Return To Desert Financial</span>
			</a>
		</aside>

		<p />

		<aside class="content-container tile-container">
			<NCUALink />
		</aside>
	</section>
</template>

<script>
	import axios from 'axios';
	import { Portal } from 'portal-vue';
	import { createToastInterface } from 'vue-toastification';

	import { DeclinedMessage, NCUALink } from '@/components';
	import { StsOutlinedIcon, StsTitle } from '@/components/SwitchThink';
	import { DFMark } from '@/components/SwitchThink/logos';
	import { getDeviceSize, showSpinner } from '@/mixins';
	import { toastConfig } from '@/utils';
	import { mapActions } from 'vuex';

	const toast = createToastInterface(toastConfig);

	export default {
		name: 'DeclinedLoan',
		components: {
			Portal,
			NCUALink,
			StsOutlinedIcon,
			StsTitle,
			DFMark,
			DeclinedMessage
		},
		mixins: [getDeviceSize, showSpinner],

		data() {
			return {
				companyWebsiteLink: '',
				nextSteps: []
			};
		},
		computed: {
			iconDimension() {
				return this.isMobile ? 75 : 100;
			}
		},
		async created() {
			try {
				this.showSpinner({ loading: true });

				this.logOut();

				// Set next steps here:
				this.nextSteps = [];

				const {
					data: { companyWebsiteLink }
				} = await axios.get('/api/Configuration/ExternalLinks');

				this.companyWebsiteLink = companyWebsiteLink;
			} catch (error) {
				toast.error(error);
			} finally {
				this.showSpinner({ loading: false });
			}
		},
		methods: {
			...mapActions('applicant', ['logOut'])
		}
	};
</script>

<style lang="scss" scoped>
	.declined-loan {
		padding-top: 2.5rem;

		h1 {
			text-align: center;
			font-size: 1.25rem;
			color: var(--primary);
			font-weight: 300;
			margin-left: 1rem;
		}

		p {
			font-size: 0.91rem;
			font-weight: 300;
			color: var(--primary);
			margin: 1.25rem;
			text-align: center;

			strong {
				font-weight: 700;
			}

			span {
				font-weight: 400;
				color: var(--secondary);
			}
		}

		.tile-container {
			display: flex;
			justify-content: center;

			.tile,
			::v-deep .tile {
				width: calc(100% / 3 - 1em);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 0.5rem;
				border-radius: 8px;
				border: 2px solid var(--disabled);
				color: var(--primary);
				font-weight: 300;
				font-size: 0.9rem;
				transition: opacity 1s;
				text-decoration: none;

				&:hover {
					opacity: 0.7;
				}

				span {
					margin-top: 0.5rem;
				}
			}
		}
	}
</style>
